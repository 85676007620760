import React, { memo, ReactElement, useEffect, useState } from 'react';
import { Input, Form, Select, Checkbox, Radio } from 'antd';
import clsx from 'clsx'
import { ReactComponent as SearchIcon } from '../../../assets/icons/icon-search.svg';
import { useDispatch, useSelector } from 'react-redux';
import doctorActions from "../../../redux/doctors/actions"
import styles from "./styles.module.scss"
import { useIntl } from 'react-intl';

interface DoctorsFilterSidebarProps {
  className?: string;
  initialValues?: any;
  onFilterChange: (values: any) => void
}

const customFilterOrderEnum = {
  academicTitlesCustom: {
    "Asistent Universitar": 0,
    "Profesor Universitar": 3
  },
  degreeTypes: {
    "Medic Resident": 0,
    "Medic Primar": 2
  }
}

export default memo(({ className, initialValues = {}, onFilterChange }: DoctorsFilterSidebarProps) => {
  const intl = useIntl()

  const dispatch = useDispatch()
  const loading = useSelector((state: any) => state?.doctors?.loadingFilters || false);
  const filters = useSelector((state: any) => state?.doctors?.filters || {});
  const [academicCheck, setAcademicCheck] = useState<boolean>(false)

  useEffect(() => {
    dispatch({ type: doctorActions.GET_FILTERS })
  },[])
  const specialtyList = (filters?.specialties || []).reduce((res: any, cur: any) => {
    if(res[cur.specialty.charAt(0).toLowerCase()] && Array.isArray(res[cur.specialty.charAt(0).toLowerCase()])) {
      res[cur.specialty.charAt(0).toLowerCase()].push(cur);
    } else {
      res[cur.specialty.charAt(0).toLowerCase()] = [cur]
    }
    return res
  }, {});
  return (
    <div className={clsx(styles.container, 'medi-doctors-filter-sidebar-component', className)}>
      <Form
        onValuesChange={(changedValues, allValues) => onFilterChange(allValues)}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues}
      >
        <Form.Item name="search">
          <Input placeholder={intl.formatMessage({ id: 'doctors.search' })} addonAfter={<SearchIcon />} />
        </Form.Item>

        <Form.Item name="city" label={intl.formatMessage({ id: 'doctors.city' })}>
          <Input placeholder="Brasov..." />
        </Form.Item>

        {specialtyList && Object.keys(specialtyList).length && (
          <Form.Item label={intl.formatMessage({ id: 'doctors.specialty' })} name="specialty">
            <Checkbox.Group  disabled={loading}>
              {Object.keys(specialtyList).map((key: any, i: number) => (
                <div key={i}>
                  {specialtyList[key].map((option: any, k:number) => (
                    <Checkbox key={k} value={option.id}>{intl.formatMessage({ id: "doctors.specialties." + option.specialty })}</Checkbox>
                    ))}
                </div>
              ))}
              <div className='more-specialties'>More specialties to come</div>
            </Checkbox.Group>
          </Form.Item>
        ) || null}

        <Form.Item label={intl.formatMessage({ id: "doctors.academic-title" })} name="academicCheckbox">
          <Checkbox.Group  disabled={loading}>
            <Checkbox checked={academicCheck} onChange={(e) => {
              setAcademicCheck(e.target.checked)
            }}>{intl.formatMessage({ id: "doctors.university-yes" })}</Checkbox>
          </Checkbox.Group>
          {academicCheck && filters?.academicTitles && Array.isArray(filters.academicTitles) &&
          (
            <Form.Item name="academicTitle">
              <Checkbox.Group  disabled={loading}>
                {filters?.academicTitles.map((academicTitle: any, i: number) => (
                  <Checkbox key={i} value={academicTitle.id}>{intl.formatMessage({ id: "doctors.academic-titles." + academicTitle.title })}</Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          ) || null}
        </Form.Item>


        {filters?.degreeTypes && Array.isArray(filters?.degreeTypes) && filters?.degreeTypes.length &&
         (
          <Form.Item label={intl.formatMessage({ id: 'doctors.degree-type' })} name="degreeType">
            <Checkbox.Group  disabled={loading}>
              {filters?.degreeTypes.map((degreeType: any, i: number) => (
                <Checkbox key={i} value={degreeType.id}>{degreeType.degree}</Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        ) || null}

      </Form>
    </div>
  )
})
