import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Tabs, Modal, Spin, Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import requestsActions from "../../redux/requests/actions"
import styles from "./styles.module.scss"


interface SecondOpinionDetailsModalProps {
  visible?: boolean;
  requestId: number;
  className?: string;
}

export default memo(({ visible, requestId, className }: SecondOpinionDetailsModalProps) => {
  const [form] = Form.useForm()

  const handlePatientPay = useCallback(() => dispatch({
    type: 'doctors/PATIENT_PAY',
    payload: requestId
  }),[requestId])
  
  const intl = useIntl()
  const dispatch = useDispatch()

  const { requestDetailsModalLoading: isLoading, requestDetailsModalData: data, requestDetailsDownloadLoading: downloadLoading } = useSelector((state: any) => state.requests)

  const [isRequestDeclined, setIsRequestDeclined] = useState(false)

  const triggerCloseModal = useCallback(() => dispatch({ type: requestsActions.CLOSE_REQUEST_DETAILS_MODAL }),[])
  const triggerDownloadDocument = useCallback((documentId: any) => dispatch({ type: requestsActions.DOWNLOAD_REQUEST_DOCUMENT, payload: documentId }),[])

  const triggerAcceptRequest = useCallback(() => dispatch({ type: requestsActions.ACCEPT_REQUEST, payload: requestId }),[])
  const triggerDeclineRequest = useCallback((values: any) => {
    dispatch({
      type: requestsActions.DECLINE_REQUEST,
      payload: { requestId, ...values }
    })
    setIsRequestDeclined(false)
  },[])
  const triggerFinalizeRequest = useCallback((values: any) => {
    dispatch({
      type: requestsActions.FINALIZE_REQUEST,
      payload: { requestId, ...values }
    })
    setIsRequestDeclined(false)
  },[])


  useEffect(() => {
    dispatch({
      type: requestsActions.GET_REQUEST_DETAILS,
      payload: requestId
    })
    setIsRequestDeclined(false)
  },[])

  return (
    <Modal
      className={clsx(styles.container, 'medi-second-opinion-modal-component', className)}
      // title={intl.formatMessage({ id: 'general.second-opinion-modal.title' })}
      title="Detalii cerere a doua opinie"
      visible={visible}
      onCancel={triggerCloseModal}
      footer={!isLoading && data?.status === "pending" && !data.userIsRequester && !isRequestDeclined && [
        <Button key="decline" onClick={() => setIsRequestDeclined(true)}>Refuza</Button>,
        <Button key="accept" type="primary" onClick={triggerAcceptRequest}>Accepta cerere</Button>
      ] || false}
    >
      {(isLoading || (!isLoading && !data.id)) && (<div className="text-center"><Spin size="large" /></div>) || (
        <div>
          <div className="mb-2">
            <strong>User:</strong> {data.partner.name}
            {data.status === "declined" && (
              <div>
                <strong>Status: </strong> Cerere refuzata<br />
                <strong>Motiv: </strong> <br />
                <p dangerouslySetInnerHTML={{__html: `${data?.declineReason || ''}`.replace(/\n/g,"<br />")}} />
              </div>
            ) || null}
          </div>
          <Tabs type="card">
            <Tabs.TabPane tab="Diagnostic" key="diagnostic">{data.diagnostic}</Tabs.TabPane>
            <Tabs.TabPane tab="Simptome" key="symptoms">{data.symptoms}</Tabs.TabPane>
            <Tabs.TabPane tab="Istoric" key="history">{data.history}</Tabs.TabPane>
            <Tabs.TabPane tab="Motiv consult" key="reason">{data.reason}</Tabs.TabPane>
            <Tabs.TabPane tab="Documente" key="documents">
              <div className="documents-list">
                {data.documents.map((document: any, i:number) => (
                  <Button size="small" className="mr-1 mb-1" disabled={downloadLoading} key={i} onClick={() => triggerDownloadDocument(document.id)}>{document.fileName} <i className="fa-solid fa-up-right-from-square ml-2" /> </Button> 
                ))}
              </div>
            </Tabs.TabPane>
            {((data.userIsRequester && ['complete'].indexOf(data.status) > -1) || (!data.userIsRequester && ['accepted','finalized','awaiting-payment','complete'].indexOf(data.status) > -1)) && (
              <Tabs.TabPane tab="Opinie" key="opinion" >
                {data.userIsRequester ? 
                  data.expertOpinion ? (<p dangerouslySetInnerHTML={{__html: `${data?.expertOpinion || ''}`.replace(/\n/g,"<br />")}} />)
                  : 
                  <div className="form-row text-center">
                      <div className="col-12">
                        <Button className="btn btn-primary" onClick={() => handlePatientPay()}>
                            See The Opinion
                        </Button>
                      </div>
                  </div>
                 : (
                  <div>
                    {data.status === "complete" && (
                      <p dangerouslySetInnerHTML={{__html: `${data?.expertOpinion || ''}`.replace(/\n/g,"<br />")}} />
                    ) || (
                      <Form
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        onFinish={triggerFinalizeRequest}
                        autoComplete="off"
                      >

                        <Form.Item
                          label="Va rugam introduceti interpretarea"
                          name="expertOpinion"
                          rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                        >
                          <Input.TextArea />
                        </Form.Item>

                        <div className="text-center">
                          <Button type="primary" htmlType="submit">
                            Finalizeaza cererea
                          </Button>
                        </div>
                      </Form>
                    )}
                  </div>
                )}
              </Tabs.TabPane>
            ) || null}
          </Tabs>
          {isRequestDeclined && (
            <div className="mt-5">
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={triggerDeclineRequest}
                autoComplete="off"
              >
                <Form.Item
                  label="Motiv pentru refuz"
                  name="declineReason"
                  rules={[{ required: true, message: intl.formatMessage({ id: 'form.validation.required' }) }]}
                >
                  <Input.TextArea />
                </Form.Item>

                <div className="text-center">
                  <Button className="mr-2" onClick={() => setIsRequestDeclined(false)}>
                    Anulare
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Refuza cerere
                  </Button>
                </div>
              </Form>
            </div>
          ) || null}
        </div>
      )}

    </Modal>
  )
})
