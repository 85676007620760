import React, { memo, useCallback } from 'react';
import clsx from 'clsx'
import styles from "./styles.module.scss"
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';


interface LanguageSwitcherProps {
  className?: string
}

export default memo(({ className }: LanguageSwitcherProps) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const languages = ['ro','en']

  const triggerSwitchLocale = useCallback((lang: any) => {
    dispatch({
      type:'misc/SET_APP_LANGUAGE',
      payload: lang
    })
  },[])

  return (
    <div className={clsx(styles.container, 'medi-language-switcher-component', className)}>
      {languages.map((lang, key) => (
        <a onClick={() => triggerSwitchLocale(lang)} key={key} title={lang}>
          <img src={`/images/locales/${lang}.png`} alt={lang} />
        </a>
      ))}
    </div>
  )
})
