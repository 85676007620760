import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { Radio, Button, Spin, Alert } from 'antd';
import { MenuOutlined, TableOutlined } from '@ant-design/icons';
import clsx from 'clsx'
import { GridViewItem, ListViewItem, DetailsViewItem } from "./components"
import profileActions from '../../redux/profile/actions';
import styles from "./styles.module.scss"
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';


interface DoctorTableProps {
  data: any[];

  perPage?: number;

  defaultViewStyle?: 'list' | 'grid';
  loading?: boolean;
  noDataText?: string;
  filters?: any;
  setFilters: any;
  onFiltersChange?: (filters: any) => void;
  className?: string;
}

export default memo(({ data, defaultViewStyle = 'list', noDataText = 'Nu exista date in tabel', filters, setFilters, loading, onFiltersChange, className }: DoctorTableProps) => {
  const intl = useIntl()
  const auth = useSelector((state: any) => state.auth);
  const dispatch = useDispatch()
  const [viewStyle, setViewStyle] = useState(defaultViewStyle)
  const [viewDetails, setViewDetails] = useState(false) as any
  const [filterValues, setFilterValues] = useState(filters)
  const [myProfile, setMyProfile] = useState({} as { userType: string, id: number });
  const RowComponent = viewStyle === 'list' && ListViewItem || GridViewItem
  
  const triggerFilterChange = useCallback((key: any, value: any) => setFilterValues({ ...filterValues, [key]: value }),[filterValues])
  
  useEffect(() => {
    if(onFiltersChange) {
      onFiltersChange(filterValues)
    }
  },[filterValues]);

  const profile = useSelector((state: any) => state?.profile.general || {});

  useEffect(()=> setMyProfile({userType: profile.userType, id: profile.id}) , [profile]);
  useEffect(() => {
    dispatch({ type: profileActions.GET_UPDATE_ACCOUNT })
  }, [])

  // TODO - handle pagination

  return (
    <div className={clsx(styles.container, 'medi-doctor-table-component', className)}>
      <div className="doctor-table-header">
        {viewDetails && (
          <Button className="details-btn" onClick={() => setViewDetails(false)}>
            ← {intl.formatMessage({ id: 'doctors.back-to-results' })}
          </Button>
        ) || (
          <>
            <div className="filters">
              <Radio.Group defaultValue="all" buttonStyle="solid" className="filter display-filter" onChange={(value) => triggerFilterChange('showOnly',value)}>
                <Radio.Button value="all">{intl.formatMessage({ id: 'doctors.filters.all' })}</Radio.Button>
                <Radio.Button value="available">{intl.formatMessage({ id: 'doctors.filters.available' })}</Radio.Button>
              </Radio.Group>
            </div>

            <div className="view-options">
              <Button.Group className="view-style">
                <Button icon={<MenuOutlined />} size="small" onClick={() => setViewStyle('list')} />
                <Button icon={<TableOutlined />} size="small" onClick={() => setViewStyle('grid')} />
              </Button.Group>
            </div>
          </>
        )}

      </div>

      <div className={`doctor-table-body ${viewStyle}-view`}>
        {viewDetails && (
          <DetailsViewItem {...viewDetails} doctors={data} setViewDetails={setViewDetails}/>
        ) || (
          <>
            {typeof loading !== 'undefined' && loading && (
              <div className="medi-doctor-table-loading">
                <Spin tip="Loading..." />
              </div>
            ) || null}
            {data.length > 0 && (
              <>
                { data.map((item, key) => {
                  const data = { ...item, 
                    specialty: intl.formatMessage({ id: "doctors.specialties." + item.specialty }),
                    academicTitle: intl.formatMessage({ id: "doctors.academic-titles." + item.academicTitle })
                  };
                    return (<RowComponent {...data} key={key} onClick={() => {  
                      setFilters({
                        showOnly: 'all'
                      }) 
                      setViewDetails(data)}} user_id={myProfile.id} admin={auth?.admin} />) 
                  })
                }
              </>
            ) || (<div className="medi-doctor-table-no-data">{noDataText}</div>)}
          </>
        )}
      </div>
    </div>
  )
})
